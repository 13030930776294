const pageTitle = "涂鸦-Doodles"

export default function getPageTitle(title) {
    let lasttitle = '';
    if (!title) {
        lasttitle = title
    } else {
        lasttitle = title + '-' + pageTitle
    }
    return lasttitle
}
