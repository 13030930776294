import Vue from 'vue'
import Vuex from 'vuex'
import doodles from './modules/doodles'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    doodles
  }
})

export default store
