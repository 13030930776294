import {list} from '@/api/api'



const state = {
    doodles: []
}

const mutations = {
    getDoodles: (state, rs) => {
        state.doodles = rs
    },

}

const actions = {
    getList({commit}, sendData) {
        return new Promise((resolve, reject) => {
            list(sendData).then((rs) => {
                const {data} = rs
                commit('getDoodles', data.list)
                resolve(data.list)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

