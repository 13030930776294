<template>
    <router-view :cur="cur">
    </router-view>
</template>

<script>

export default {
    name: 'house',
    components: {},
    data() {
        return {
            cur:0

        }
    },
    watch: {
      $route(to) {
            if (to.path.length>1) {
                this.cur = Number(to.path.split('/')[2])
            } else {
                this.cur = 0
            }
        }
    },
    methods: {},
    computed: {}
}
</script>
