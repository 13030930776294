import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    {
        path: '/',
        component: () => import('@/views/index'),
        meta: {title: '首页'}
    },
    {
        path: '/category/:id',
        component: () => import('@/views/index'),
        meta: {title: '首页'}
    },
    {
        path: '/doodles/:url',
        component: () => import('@/views/detail'),
        meta: {title: '详情'}
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        meta: {title: '404'}
    },
    {path: '*', redirect: '/404'}
]

const router = new Router({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            //return {x: 0, y: 0}
        }
    }
})
export default router
